<!-- <div class="bg-blue-500 lg:h-44 h-auto py-7 px-14 mt-3 text-white">
  <div class="flex lg:flex-row flex-col justify-between">
    <div class="left">
      <div class="lg:block flex items-center justify-between">
        <div class="logo-wrapper" class="logo">
          <a [routerLink]="['/home']" role="button" class="logo-link">
            <img
              src="assets/images/seech-logo.png"
              alt="seech logo"
              class="logo-link-img"
            />
          </a>
          <div class="mt-3 block lg:hidden">
            <p>Aspire Inovate Rule ...</p>
          </div>
        </div>

        <div class="block lg:hidden w-[40%]">
          <p class="mb-4 font-medium">Seech Payments</p>
          <p class="mb-2">Mass Payments</p>
          <p>Assets</p>
        </div>

        <div class="mt-2 lg:block hidden">
          <p>Aspire Inovate Rule ...</p>
        </div>
      </div>
      <div class="mt-2.5 lg:block hidden">
        <p>© {{ currentYear }} Seech . All rights reserved.</p>
      </div>
    </div>
    <div class="right lg:mt-0 mt-3">
      <div class="flex lg:gap-16 justify-between">
        <div>
          <p class="mb-4 font-medium">Seech Products</p>
          <p class="mb-2">Seech for Startups</p>
          <p>Seech for Developers</p>
        </div>
        <div class="lg:block hidden">
          <p class="mb-4 font-medium">Seech Payments</p>
          <p class="mb-2">Mass Payments</p>
          <p>Assets</p>
        </div>
        <div class="lg:w-auto w-[40%]">
          <p class="mb-4 font-medium">Support</p>
          <p class="mb-2">FAQ</p>
          <p>Contact</p>
        </div>
      </div>
    </div>
  </div>

  <div class="border-t-[0.8px] border-twhite py-3 mt-4 block lg:hidden">
    <p class="text-center footer-item">© {{ currentYear }} Seech . All rights reserved.</p>
  </div>
</div> -->

<div
  class="bg-blue-500 lg:h-44 h-auto py-7 px-14 mt-3 text-white lg:!block !hidden"
>
  <div class="flex lg:flex-row flex-col justify-between">
    <div class="left">
      <div class="lg:block flex items-center justify-between">
        <div class="logo-wrapper">
          <a [routerLink]="logoLink" role="button" class="logo-link">
            <img [src]="logoUrl" alt="logo" class="logo-link-img" />
          </a>
          <div class="mt-3 block lg:hidden">
            <p>{{ slogan }}</p>
          </div>
        </div>

        <div class="block lg:hidden w-[40%]">
          <p class="mb-4 font-medium">Seech Payments</p>
          <p *ngFor="let payment of payments" class="mb-2">{{ payment }}</p>
        </div>

        <div class="mt-2 lg:block hidden">
          <p>{{ slogan }}</p>
        </div>
      </div>
      <div class="mt-2.5 lg:block hidden">
        <p>© {{ currentYear }} Seech. All rights reserved.</p>
      </div>
    </div>
    <div class="right lg:mt-0 mt-3">
      <div class="flex lg:gap-16 justify-between">
        <div>
          <p class="mb-4 font-medium">Seech Products</p>
          <p *ngFor="let product of products" class="mb-2">{{ product }}</p>
        </div>
        <div class="lg:block hidden">
          <p class="mb-4 font-medium">Seech Payments</p>
          <p *ngFor="let payment of payments" class="mb-2">{{ payment }}</p>
        </div>
        <div class="lg:w-auto w-[40%]">
          <p class="mb-4 font-medium">Support</p>
          <p *ngFor="let supportItem of support" class="mb-2">
            {{ supportItem }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="border-t-[0.8px] border-t-white py-3 mt-4 block lg:hidden">
    <p class="text-center footer-item">
      © {{ currentYear }} Seech. All rights reserved.
    </p>
  </div>
</div>
