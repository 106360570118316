<div
  class="img-placeholder bg-white text-primary font-bold border-2 border-primary"
  [ngStyle]="{
    width: size,
    height: size,
    'font-size': 'calc(' + size + ' / 2.5)'
  }"
>
  <p class="mt-2">{{ getFullName | extractInitials }}</p>
</div>
